import { useMemo } from 'react';

import useRequest from './useRequest';

const requestConfig = {
  url: '/api/quiz/commodity',
};

function useBinaryListData() {
  const { data, error } = useRequest(requestConfig);
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return { done: true, data };
    }
    return {};
  }, [data, error]);
  return result.data;
}

export default useBinaryListData;
